import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BackendService, ProjectAccountingLink, Line } from '../backend.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-project-acct-link',
  templateUrl: './project-acct-link.component.html',
  styleUrls: ['./project-acct-link.component.css']
})
export class ProjectAcctLinkComponent implements OnInit {

  project_id: number;
  project: ProjectAccountingLink;
  lines: Line[];
  modal_selected_line: number;
  modal_lines: Line[];

  @ViewChild('modal_content', {static: false})
  private modal_content: TemplateRef<any>;

  constructor(private backend: BackendService, private route: ActivatedRoute, private modalService: NgbModal) { }

  ngOnInit() {
    this.project_id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.load_project();
  }

  load_project() {
    this.backend.get_project_accounting_link(this.project_id).subscribe(
      data => {
        this.project = data;
        this.project.accounting_lines.sort((a, b) => (a.line != null && b.line == null) ? 1 : ((a.date > b.date) ? 1 : -1));
      }
    );

    this.backend.get_project(this.project_id).subscribe(
      data => {
        this.lines = data.lines;
      }
    );
  }

  save() {
    this.backend.patch_project_accounting_link(this.project).subscribe(
      data => {
        this.project = data;
        this.project.accounting_lines.sort((a, b) => (a.line != null && b.line == null) ? 1 : ((a.date > b.date) ? 1 : -1));
        alert("Sauvegarde réussie !");
      }
    );
  }

  restore() {
    if(!confirm("Annuler les modifications ?")) return;
    this.project = null;
    this.load_project();
  }

  delete_line(al) {
    al.confirm_delete = true;
  }

  link_line(al) {
    let c = al.code.toString().substring(0, 1)
    this.modal_lines = this.lines.filter(x => x.code.toString().substring(0, 1) == c)
    if(al.line) {
      this.modal_selected_line = al.line.id;
    } else {
      this.modal_selected_line = null;
    }

    this.modalService.open(this.modal_content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if(result==null) {
        al.line = null;
      } else {
        al.line = this.lines.find(x => x.id == result)
      }
    }, (reason) => {
      // No action for dismiss
    });
  }

  link_line_auto(al) {
    al.line = al.auto_line;
    al.auto_line = null;
  }

}
