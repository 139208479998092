import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  baseUrl: string;
  apiUrl: string;
  user: User;
  oauth2_code: string = null;
  token: string = null;
  year: Year;

  years: Year[];
  codes: Code[];
  projects: Project[];
  groupings: Grouping[];
  freezes: Freeze[];
  code_groups: CodeGroup[];

  constructor(private http: HttpClient, private window: Window) {
    this.token = localStorage.getItem("token");
    if(window.location.hostname=="localhost" || window.location.hostname=="127.0.0.1") {
      this.baseUrl = 'http://localhost:8000/';
    } else {
      //this.baseUrl = 'https://picsous.planete-sciences.org/backend/';
      this.baseUrl = 'https://'+window.location.hostname+'/backend/';
    }
    this.apiUrl = this.baseUrl+'api/';
    console.log("API url is: "+this.apiUrl);
  }

  api_error(error) {
    console.log(error);
    if(error.status == 401 && this.token) { // Invalid or expired token
      this.user = <User>{"logged_in":false};
      this.token = null;
      localStorage.removeItem("token");
      alert("La session a expiré. Veuillez vous reconnecter.");
      return;
    }

    if(error.detail) error = error.detail;
    else if(error.error && error.error.detail) error = error.error.detail;
    else if(error.status == 0) error = "Erreur interne au navigateur. Vérifiez que vous êtes bien connectés à internet et que le serveur est disponible.";
    else if(error.message) error = error.message;
    alert(error); // TODO
  }

  options(empty_body=false) {
    var headers = {};
    if(this.token) headers["Authorization"] = "Token "+this.token;
    if(!empty_body) headers["Content-Type"] = "application/json";

    return { headers: new HttpHeaders (headers), }
  }

  refresh_user() {
    if(this.oauth2_code) {
      this.http.post(this.apiUrl + "user/login_oauth2", {"code": this.oauth2_code, "uri": window.location.origin + "/"}, this.options()).subscribe(
        data => {
          this.token = data.toString();
          localStorage.setItem("token", this.token);
          this.refresh_user();
        },
        error => {
          this.api_error(error)
          this.refresh_user();
        }
      );
      this.oauth2_code = null;
      return;
    }
    this.http.get<User>(this.apiUrl + "user", this.options(true)).subscribe(
      data => {
        this.user = data
        if(this.user.logged_in) this.load_generic_data();
      },
      error => this.api_error(error)
    );
  }

  user_login(username, password) {
    this.http.post(this.apiUrl + "user/login", {"username": username, "password": password}, this.options()).subscribe(
      data => {
        this.token = data.toString();
        localStorage.setItem("token", this.token);
        this.refresh_user();
      },
      error => this.api_error(error)
    );
  }

  user_logout() {
    this.token = null;
    localStorage.removeItem("token");
    this.http.post(this.apiUrl + "user/logout", "", this.options()).subscribe(
      data => this.refresh_user(),
      error => this.api_error(error)
    );
  }

  load_generic_data() {
    this.http.get<Year[]>(this.apiUrl + "year", this.options(true)).subscribe(
      data => {
        this.years = data;
        this.years.sort((a, b) => (a.year > b.year) ? 1 : -1);
        let year = this.years.filter(x => !x.closed)[0];
        if(!year) year = this.years[0];
        this.change_year(year);
      },
      error => this.api_error(error)
    );

    this.http.get<Code[]>(this.apiUrl + "code", this.options(true)).subscribe(
      data => {
        this.codes = data;
        this.codes.sort((a, b) => (a.code > b.code) ? 1 : -1);
      },
      error => this.api_error(error)
    );

    this.http.get<CodeGroup[]>(this.apiUrl + "code_group", this.options(true)).subscribe(
      data => {
        this.code_groups = data;
        this.code_groups.sort((a, b) => (a.order > b.order) ? 1 : -1);
      },
      error => this.api_error(error)
    );
  }

  change_year(year) {
    if(typeof year == 'number') year = this.years.filter(x => x.year == year)[0];

    if(this.year == year) return;
    this.projects = this.groupings = this.freezes = null;
    this.year = year;
    this.load_year_data();
  }

  load_year_data() {
    this.http.get<Project[]>(this.apiUrl + "project?year=" + this.year.year, this.options(true)).subscribe(
      data => {
        this.projects = data;
        this.projects.sort((a, b) => (a.code > b.code) ? 1 : -1);
      },
      error => this.api_error(error)
    );
    this.http.get<Grouping[]>(this.apiUrl + "grouping?year=" + this.year.year, this.options(true)).subscribe(
      data => {
        this.groupings = data;
        this.groupings.sort((a, b) => (a.name > b.name) ? 1 : -1);
      },
      error => this.api_error(error)
    );
    this.http.get<Freeze[]>(this.apiUrl + "freeze?year=" + this.year.year, this.options(true)).subscribe(
      data => {
        this.freezes = data;
        this.freezes.sort((a, b) => (a.name > b.name) ? 1 : -1);
      },
      error => this.api_error(error)
    );
  }

  get_project(project_id): Observable<Project> {
    return new Observable((subscriber) => {
      this.http.get<Project>(this.apiUrl + "project/" + project_id, this.options(true)).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  patch_project(project: Project): Observable<Project> {
    let data = <ProjectPATCH>{"id": project.id, "lines": project.lines};
    return new Observable((subscriber) => {
      this.http.patch<Project>(this.apiUrl + "project/" + project.id, data, this.options()).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  get_year_control9(year): Observable<Control9[]> {
    return new Observable((subscriber) => {
      this.http.get<Control9[]>(this.apiUrl + "year/" + year + "/control9", this.options(true)).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  get_report(type, id, depth): Observable<Report> {
    if (['year', 'grouping', 'freeze'].indexOf(type) == -1) return null;
    return new Observable((subscriber) => {
      this.http.get<Report>(this.apiUrl + type + "/" + id + "/report?depth=" + depth, this.options(true)).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  get_report_excel(type, id, depth): Observable<Report> {
    if (['year', 'grouping', 'freeze'].indexOf(type) == -1) return null;
    let options = this.options(true);
    options['responseType'] = 'blob';
    return new Observable((subscriber) => {
      this.http.get<Report>(this.apiUrl + type + "/" + id + "/report/export?depth=" + depth, options).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  get_report_accounting(year): Observable<AccountingReport[]> {
    return new Observable((subscriber) => {
      this.http.get<AccountingReport[]>(this.apiUrl + "year/" + year + "/accounting_report", this.options(true)).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  get_search_lines(conditions): Observable<Line[]> {
    let options = this.options(true);
    options['params'] = conditions;
    return new Observable((subscriber) => {
      this.http.get<Line[]>(this.apiUrl + "project_line", options).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  get_search_lines_excel(conditions): Observable<any> {
    let options = this.options(true);
    options['params'] = conditions;
    options['responseType'] = 'blob';
    return new Observable((subscriber) => {
      this.http.get<any>(this.apiUrl + "project_line/export", options).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  get_project_accounting(project_id): Observable<ProjectAccounting> {
    return new Observable((subscriber) => {
      this.http.get<ProjectAccounting>(this.apiUrl + "project_accounting/" + project_id, this.options(true)).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  refresh_project_accounting(project_id): Observable<ProjectAccounting> {
    return new Observable((subscriber) => {
      this.http.post<ProjectAccounting>(this.apiUrl + "project_accounting/" + project_id + "/refresh", null, this.options()).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  get_project_accounting_link(project_id): Observable<ProjectAccountingLink> {
    return new Observable((subscriber) => {
      this.http.get<ProjectAccountingLink>(this.apiUrl + "project_accounting_link/" + project_id, this.options(true)).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }

  patch_project_accounting_link(project: ProjectAccountingLink): Observable<ProjectAccountingLink> {
    return new Observable((subscriber) => {
      this.http.patch<ProjectAccountingLink>(this.apiUrl + "project_accounting_link/" + project.id, project, this.options()).subscribe(
        data => subscriber.next(data),
        error => this.api_error(error)
      );
    });
  }
}

export interface User {
  logged_in: boolean;
  id?: number;
  username?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  is_staff?: boolean;
  oauth2?:{
    url: string;
    client: string;
  };
}

export interface Year {
  year: number;
  closed: boolean;
}

export interface Code {
  code: number;
  name: string;
  no_taxes: boolean;
  group_id: number;
}

export interface CodeGroup {
  id?: number;
  category: number;
  name: string;
  order: number;
}

export interface Tax {
  id: number;
  year: number;
  name: string;
  code_min: number;
  code_max: number;
  percent: number;
  target: number;
}

export interface Project {
  id: number;
  year: number;
  name: string;
  code: number;
  direction?: string;
  taxes?: Tax[];
  can_write: boolean;
  last_version?:{
    id: number;
    datetime: string;
    author: string;
  };
  subprojects?: Subproject[];
  lines?: Line[];
}

export interface ProjectPATCH {
  id: number;
  lines: Line[];
}

export interface Subproject {
  id: number;
  code: number;
  name: string;
}

export interface Grouping {
  id: number;
  year: number;
  name: string;
  code_min: number;
  code_max: number;
}

export interface Freeze {
  id: number;
  year: number;
  name: string;
  datetime: string;
}

export interface Line {
  id: number;
  project?: number;
  subproject?: number;
  order?: number;
  first_month: number;
  last_month: number;
  code: number;
  description: string;
  amount: number; // Decimal
  probability: number;
  comment: string;
  target_code: number;
  variation: string;
  no_taxes: boolean;
}

export interface Control9 {
  ok: boolean;
  code_6: number;
  code_7: number;
  project_6: Project;
  project_7: Project;
  total_6: number;
  total_7: number;
}

export interface AccountingReport {
  id: number;
  name: string;
  code: number;
  amount6: number;
  amount6_used: number;
  amount7: number;
  amount7_used: number;
}

export interface Report {
  rows:{
    key: string;
    level: number;
    name: string;
  };
  cols:{
    name: string;
    values:{
      [details: string] : string;
    };
  };
  totals?:{
    [details: string] : string;
  };
}

export interface ProjectAccounting {
  id: number;
  year: number;
  name: string;
  code: number;
  null_count: number;
  deleted_count: number;
  lines: {
    id: number;
    code: number;
    description: string;
    amount: number;
    amount_used: number;
    accounting_lines: {
      id: number;
      date: string;
      code: number;
      description: string;
      more_infos: string;
      amount: number;
      deleted: boolean;
    }[];
  };
}

export interface ProjectAccountingLink {
  id: number;
  year: number;
  name: string;
  code: number;
  can_write: boolean;
  accounting_lines: {
    id: number;
    date: string;
    code: number;
    description: string;
    more_infos: string;
    amount: number;
    deleted: boolean;
    confirm_delete?: boolean;
    line: {
      id: number;
      code: number;
      description: string;
      amount: number;
    };
    auto_line: {
      id: number;
      code: number;
      description: string;
      amount: number;
    };
  }[];
}
