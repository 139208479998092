import { Component, AfterViewInit } from '@angular/core';
import { BackendService } from './backend.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

  constructor(private backend: BackendService) {
    // Saves query before angular router cleans it
    let params = new URLSearchParams(location.search);
    if(params.get("error")) {
      alert(params.get("error_description")+" ("+params.get("error")+")");
    }
    if(params.get("code")) {
      if(params.get("state") == "todo") { // TODO
        backend.oauth2_code = params.get("code");
      } else {
        alert("Invalid state.");
      }
    }
  }

  ngAfterViewInit() {
    this.backend.refresh_user();
  }
  
  admin_redirect() {
    window.open(this.backend.baseUrl+"admin/");
  }
    
}
