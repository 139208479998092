import { Component, OnInit } from '@angular/core';
import { BackendService, Control9 } from '../backend.service';

@Component({
  selector: 'app-control9',
  templateUrl: './control9.component.html',
  styleUrls: ['./control9.component.css']
})
export class Control9Component implements OnInit {

  year: number;
  data: Control9[];
  total6: number;
  total7: number;

  constructor(private backend: BackendService) { }

  ngOnInit() {
    this.year = this.backend.year.year; // TODO: Refresh on year change
    this.refresh();
  }
  
  refresh() {
    this.data = null;
    this.backend.get_year_control9(this.year).subscribe(
      data => {
        this.data = data;
        this.total6 = 0.0;
        this.total7 = 0.0;
        data.forEach(function(element: Control9) {
          this.total6 += element.total_6*1.0;
          this.total7 += element.total_7*1.0;
        }.bind(this));
      }
    );
    
  }

}
