import { Component, OnInit } from '@angular/core';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;

  constructor(private backend: BackendService) { }

  ngOnInit() {
  }
  
  login() {
    if(!this.username || !this.password) return;
    this.backend.user_login(this.username, this.password);
  }

  login_oauth2() {
    window.location.assign(this.backend.user.oauth2.url + "?" + new URLSearchParams({
      response_type: "code",
      client_id: this.backend.user.oauth2.client,
      redirect_uri: window.location.origin + "/",
      state: "todo"
    }).toString());
  }

}
