import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements AfterViewInit {

  constructor(private backend: BackendService, private router: Router) { }

  ngAfterViewInit() {
  }

  change_year(year) {
    this.backend.change_year(year);
    this.router.navigate(['/']);
  }

}
