import { Component, OnInit } from '@angular/core';
import { BackendService, AccountingReport } from '../backend.service';

@Component({
  selector: 'app-report-acct',
  templateUrl: './report-acct.component.html',
  styleUrls: ['./report-acct.component.css']
})
export class ReportAcctComponent implements OnInit {

  year: number;
  data: AccountingReport[];
  total_amount6: number;
  total_amount6_used: number;
  total_amount7: number;
  total_amount7_used: number;

  constructor(private backend: BackendService) { }

  ngOnInit() {
    this.year = this.backend.year.year; // TODO: Refresh on year change
    this.refresh();
  }
  
  refresh() {
    this.data = null;
    this.backend.get_report_accounting(this.year).subscribe(
      data => {
        this.data = data;
        this.total_amount6 = data.reduce((sum, current) => sum + Number(current.amount6), 0);
        this.total_amount6_used = data.reduce((sum, current) => sum + Number(current.amount6_used), 0);
        this.total_amount7 = data.reduce((sum, current) => sum + Number(current.amount7), 0);
        this.total_amount7_used = data.reduce((sum, current) => sum + Number(current.amount7_used), 0);
      }
    );
    
  }

}
