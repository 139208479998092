import { Component, OnInit } from '@angular/core';
import { BackendService, Project, Code, CodeGroup, Line } from '../backend.service';
import { downloadFile } from '../utils';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  filter_project: number = 0;
  filter_code: number = 0;
  filter_group: number = 0;
  filter_freeze: number = 0;
  filter_desc: string = "";
  
  result: Line[];

  constructor(private backend: BackendService) { }

  ngOnInit() {
  }
  
  build_conditions() {
    let conditions = {version__project__year: this.backend.year.year};
    if(this.filter_project > 0) conditions["version__project__code"] = this.filter_project;
    if(this.filter_code > 0) conditions["code__code"] = this.filter_code;
    if(this.filter_group > 0) conditions["code__group"] = this.filter_group;
    if(this.filter_freeze > 0) conditions["freeze"] = this.filter_freeze;
    if(this.filter_desc) conditions["description"] = this.filter_desc;
    return conditions;
  }
  
  do_search() {
    this.backend.get_search_lines(this.build_conditions()).subscribe(
      data => {
        this.result = data;
      }
    );
  }
  
  do_export() {
    this.backend.get_search_lines_excel(this.build_conditions()).subscribe(
      response => downloadFile(response)
    );
  }

}
