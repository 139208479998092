import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BackendService, Project, Line, Tax } from '../backend.service';
import { HotTableRegisterer } from '@handsontable/angular';

import Handsontable from 'handsontable';
import 'handsontable/languages/fr-FR';

// From https://github.com/handsontable/angular-handsontable/issues/21
import * as numbro from 'numbro';
import frFR from 'numbro/languages/fr-FR';
(numbro as any).registerLanguage(frFR);

interface Stat {
  charges: number;
  frais: number;
  produits: number;
  resultat: number;
}

interface Stats {
  n: Stat; // Nominal
  p: Stat; // Pondéré
}

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})
export class ProjectEditComponent implements OnInit {

  months = [1, 2 , 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  variations = ["fixe", "variable"];
  project: Project;
  project_id: number;
  hotRegisterer: HotTableRegisterer;
  readonly: boolean;
  stats: Stats;
  taxes_percent: number;
  codes: number[]; // Code[]
  subprojects: number[]; // Subproject[]

  hotColumns = [
    {
      data: 'subproject',
      type: 'dropdown',
      strict: true,
      allowInvalid: true,
      source: (function (query, process) {
        process(this.subprojects);
      }).bind(this)
    },
    {
      data: 'first_month',
      type: 'numeric',
      //type: 'dropdown',
      //source: this.months
    },
    {
      data: 'last_month',
      type: 'numeric',
      //type: 'dropdown',
      //source: this.months
    },
    {
      data: 'code',
      type: 'dropdown',
      strict: true,
      allowInvalid: true,
      source: (function (query, process) {
        process(this.codes); // Async load, codes may not be ready during table setup
      }).bind(this)
    },
    {
      data: 'description'
    },
    {
      data: 'amount',
      type: 'numeric',
      numericFormat: {
        pattern: {
            output: "currency",
            spaceSeparated: true,
            thousandSeparated: true,
            mantissa: 2
        },
        culture: 'fr-FR'
      }
    },
    {
      data: 'probability',
      type: 'numeric',
      numericFormat: {
        pattern: {
            output: "percent",
            spaceSeparated: true,
            mantissa: 1,
            optionalMantissa: true
        },
        culture: 'fr-FR'
      }
    },
    {
      data: 'variation',
      type: 'dropdown',
      source: this.variations
    },
    {
      data: 'comment'
    },
    {
      data: 'target_code',
      type: 'numeric'
    },
    {
      data: 'no_taxes',
      type: 'checkbox',
      readOnly: true
    }
  ] as any[];
  hotColumnsHeaders = ["Sous-projet", "M &lt;", "M &gt;", "Compte", "Intitulé", "Montant", "Proba.", "Variation", "Commentaire (facultatif)", "Projet lié", "Exempt."];
  hotColumnsWidths = [60, 30, 30, 100, 240, 60, 40, 50, 140, 50, 50];

  hotSettings: Handsontable.GridSettings = {
    columns: this.hotColumns,
    colHeaders: this.hotColumnsHeaders,
    colWidths: this.hotColumnsWidths,
    rowHeaders: true,
    dropdownMenu: ["filter_by_condition", "filter_by_value", "filter_action_bar"],
    filters: true,
    stretchH: "all",
    minSpareRows: 1,
    language: "fr-FR",
    contextMenu: ['undo', 'redo', '---------', 'row_above', 'row_below', 'remove_row'],
    afterChange: (changes) => {
      this.update_stats();
    }
  }

  hotSettingsReadOnly: Handsontable.GridSettings = {
    readOnly: true,
    columns: this.hotColumns,
    colHeaders: this.hotColumnsHeaders,
    colWidths: this.hotColumnsWidths,
    rowHeaders: true,
    dropdownMenu: ["filter_by_condition", "filter_by_value", "filter_action_bar"],
    filters: true,
    stretchH: "all",
    language: "fr-FR",
    contextMenu: false,
  }

  constructor(private backend: BackendService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.hotRegisterer = new HotTableRegisterer();
    this.readonly = true; // Will change to false, if needed, after load
    this.project_id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.load_project();
    //this.load_codes(); // FIXME: this.backend.codes is not loaded yet!
  }

  load_codes() {
    /*this.codes = this.backend.codes.reduce(function(map, obj) {
      map[obj.code] = obj.name;
      return map;
    }, {});*/
    this.codes = this.backend.codes.map(x => x.code).sort();
    //console.log(this.codes);
  }

  save() {
    let i = 1;
    let error = "";
    this.project.lines.forEach(function(element) {
      element.order = null;
      if(!element.code && !element.amount) return;
      if(!element.probability) element.probability = 1;
      if(element.comment == null) element.comment = ''; // Django bugfix
      if(element.no_taxes == null) element.no_taxes = false; // Same...
      if(!element.target_code) element.target_code = null; // Same...
      if(!element.code || !element.first_month || !element.last_month || !element.description || !element.variation) {
        error = "Ligne incomplète, impossible de sauvegarder.";
        return;
      }
      if(!element.amount) element.amount = 0;
      if(!this.codes.includes(element.code)) {
        error = "Code comptable incorrect, impossible de sauvegarder.";
        return;
      }
      if(element.code.toString().substring(0, 1) == '9' && !element.target_code) {
        error = "Projet lié obligatoire pour un code en 9.";
        return;
      }
      if(element.probability<=0 || element.probability>1) {
        error = "Probabilité 0% ou infrieure, ou supérieure à 100%, impossible.";
        return;
      }
      if(element.code.toString().substring(0, 1) == '9' && element.probability!=1) {
        error = "Probabilité inférieure à 100% impossible pour un code en 9.";
        return;
      }
      element.order = i++;
    }, this);
    if(error) {
      alert(error);
      return;
    }

    this.project.lines = this.project.lines.filter(x => x.order);

    this.backend.patch_project(this.project).subscribe(
      data => {
        this.project = data;
        alert("Sauvegarde réussie !");
      }
    );
  }

  restore() {
    if(!confirm("Restaurer la dernière version enregistrée ?")) return;
    this.project = null;
    this.stats = null;
    this.load_project();
  }

  load_project() {
    this.backend.get_project(this.project_id).subscribe(
      data => {
        this.project = data;
        this.readonly = !this.project.can_write;
        this.project.lines.sort((a, b) => (a.order > b.order) ? 1 : -1);
        if(this.project.year != this.backend.year.year) this.backend.change_year(this.project.year);
        this.calc_taxes();
        this.update_stats();
        this.load_codes();
        //this.subprojects = ['00'].concat(this.project.subprojects.map(x => x.code.toString().padStart(2, '0'))).sort();
        this.subprojects = [0].concat(this.project.subprojects.map(x => x.code)).sort();
      }
    );
  }

  insert_row() {
    this.hotRegisterer.getInstance("hot").alter('insert_row');
  }

  undo() {
    (this.hotRegisterer.getInstance("hot") as any).undo(); // TS HACK - Missing undo() in module declaration
  }

  redo() {
    (this.hotRegisterer.getInstance("hot") as any).redo(); // TS HACK - Missing redo() in module declaration
  }

  calc_taxes() {
    let taxes = 0;
    this.project.taxes.forEach(function(tax: Tax) {
      taxes += tax.percent*1.0;
    });
    this.taxes_percent = taxes;
  }

  update_stats() {
    //console.log(this.version.lines);
    let n = <Stat>{charges:0, frais:0, produits:0, resultat:0};
    let p = <Stat>{charges:0, frais:0, produits:0, resultat:0};

    this.project.lines.forEach(function(element: Line) {
      if(!element.code || !element.amount) return;
      let code = element.code.toString();
      let amount = element.amount*1.0;
      let probability = 1;
      if(element.probability) probability = element.probability*1.0;
      if(code.substring(0, 1) == '6' || code.substring(0, 2) == '96') {
        n.charges += amount;
        p.charges += amount * probability;
        let code_obj = this.backend.codes.find(c => c.code == code);
        if(!element.no_taxes && code_obj && !code_obj.no_taxes) {
          n.frais += amount * this.taxes_percent;
          p.frais += amount * probability * this.taxes_percent;
        }
      } else if(code.substring(0, 1) == '7' || code.substring(0, 2) == '97') {
        n.produits += amount;
        p.produits += amount * probability;
      }
    }.bind(this));

    n.resultat = n.produits - n.charges - n.frais;
    p.resultat = p.produits - p.charges - p.frais;
    this.stats = <Stats>{n:n, p:p};
  }

}
