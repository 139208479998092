import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BackendService, ProjectAccounting } from '../backend.service';

@Component({
  selector: 'app-project-acct',
  templateUrl: './project-acct.component.html',
  styleUrls: ['./project-acct.component.css']
})
export class ProjectAcctComponent implements OnInit {

  project_id: number;
  project: ProjectAccounting;
  ebp_refresh: boolean = false;

  constructor(private backend: BackendService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.project_id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.load_project();
  }

  load_project() {
    this.backend.get_project_accounting(this.project_id).subscribe(
      data => {
        this.project = data;
      }
    );
  }

  refresh() {
    this.ebp_refresh = true;
    this.project = null;
    this.backend.refresh_project_accounting(this.project_id).subscribe(
      data => {
        alert("Mise à jour réussie !");
        this.project = data;
        this.ebp_refresh = false;
      }
    );
  }
}
