import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProjectsComponent } from './projects/projects.component';
import { ProjectEditComponent } from './project-edit/project-edit.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportComponent } from './report/report.component';
import { ReportAcctComponent } from './report-acct/report-acct.component';
import { Control9Component } from './control9/control9.component';
import { SearchComponent } from './search/search.component';
import { ProjectAcctComponent } from './project-acct/project-acct.component';
import { ProjectAcctLinkComponent } from './project-acct-link/project-acct-link.component';

const routes: Routes = [
  { path: 'projects', component: ProjectsComponent },
  { path: 'project/:id', component: ProjectEditComponent },
  { path: 'project/:id/accounting', component: ProjectAcctComponent },
  { path: 'project/:id/link', component: ProjectAcctLinkComponent },
  { path: 'report/all', component: ReportComponent, data: {"report_type": "year"} },
  { path: 'report/group/:id', component: ReportComponent, data: {"report_type": "grouping"} },
  { path: 'report/freeze/:id', component: ReportComponent, data: {"report_type": "freeze"} },
  { path: 'report/accounting', component: ReportAcctComponent },
  { path: 'control9', component: Control9Component },
  { path: 'search', component: SearchComponent },
  { path: 'profile', component: ProfileComponent },
  //{ path: 'dashboard', component: DashboardComponent },
  { path: '', redirectTo: '/projects', pathMatch: 'full' }, // TODO: redirect to /dashboard
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
