import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Data } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BackendService, Report } from '../backend.service';
import { downloadFile } from '../utils';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  data: Report;
  report_name: string;
  source_type: string;
  source_id: number;
  depth: number;

  constructor(private backend: BackendService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe((param: Params) => {
      this.source_id = parseInt(param.get('id'));
      this.depth = 3;
      this.refresh();
      return param;
    });
    
    this.route.data.subscribe((data: Data) => {
      this.source_type = data.report_type;
      if(this.source_type=="year" && !this.source_id) this.source_id = this.backend.year.year;
      this.depth = 3;
      this.refresh();
      return data;
    });
  }
  
  set_depth(depth) {
    this.depth = depth;
    this.refresh();
  }
  
  refresh() {
    this.data = null;
    if(!this.source_type || !this.source_id) return;
    this.backend.get_report(this.source_type, this.source_id, this.depth).subscribe(
      data => {
        this.data = data;
        
        if(this.source_type=="year") {
          this.report_name = "complet année "+this.source_id;
        } else if(this.source_type=="grouping") {
          let search = this.backend.groupings.filter(x => x.id == this.source_id);
          if(search)
            this.report_name = "groupement "+search[0].name;
          else
            this.report_name = "groupement (inconnu)";
        } else if(this.source_type=="freeze") {
          let search = this.backend.freezes.filter(x => x.id == this.source_id);
          if(search)
            this.report_name = "d'archive "+search[0].name;
          else
            this.report_name = "d'archive (inconnu)";
        }
      }
    );
  }

  do_export() {
    this.backend.get_report_excel(this.source_type, this.source_id, this.depth).subscribe(
      response => downloadFile(response)
    );
  }

}
