export function downloadFile(response, file_name = "picsous.xlsx") {
  let dataType = response.type;
  let binaryData = [];
  binaryData.push(response);
  let downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
  downloadLink.setAttribute("download", file_name);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink.parentNode.removeChild(downloadLink);
}
