import { NgModule } from '@angular/core';
 
import { FeatherModule } from 'angular-feather';
import * as icons from 'angular-feather/icons';

/*
const icons = {
  i.Save,
  i.SkipBack,
  i.XCircle,
  i.Archive,
  i.Calendar,
  i.Home,
  i.File,
  i.BarChart2,
  i.Layers,
  i.PlusCircle,
  i.FileText,
  i.Bookmark,
  i.Smile,
  i.Key,
  i.Lock
};
*/
@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }
